import { testPaths } from "./../../../constants/route-paths";
import { SideNavigationProps } from "@amzn/awsui-components-react-v3/polaris/side-navigation";
import { config } from "../../../config/config";
import { AUTH_USER_TYPE } from "../../../config/constants";

// Engineering or Test
// currently amazon user will have all permission to view lab parts
const testUserItems: SideNavigationProps.Item[] = [
  {
    type: "link",
    text: "Request form",
    href: testPaths.REQEST_FORM,
  },
  {
    type: "link",
    text: "Request Status",
    href: testPaths.REQEST_STATUS,
  },
  {
    type: "link",
    text: "Third Party Lab",
    href: testPaths.LAB,
  },
  {
    type: "link",
    text: "Upload",
    href: testPaths.UPLOAD_ENGINEERING,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Engineering Link",
    href: config.ENGINEERING_WEBSITE,
    external: true,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Issue SIM",
    href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
    external: true,
  },
];

const testLabItems: SideNavigationProps.Item[] = [
  {
    type: "link",
    text: "Lab Portal",
    href: testPaths.LAB,
  },
  {
    type: "link",
    text: "Upload",
    href: testPaths.UPLOAD_ENGINEERING,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Engineering Link",
    href: config.ENGINEERING_WEBSITE,
    external: true,
  },
  { type: "divider" },
  {
    type: "link",
    text: "Issue SIM",
    href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
    external: true,
  },
];

export const getTestConfig = (userType) => {
  let sidebarItems: SideNavigationProps.Item[] = [];

  if (userType === AUTH_USER_TYPE.vendor) {
    sidebarItems = testLabItems;
  } else {
    sidebarItems = testUserItems;
  }

  const testConfig = {
    headerText: "Engineering",
    headerUrl: "/test",
    sidebarItems,
  };

  return testConfig;
};
