import React from "react";
import {
  Container,
  Header,
  RadioGroup,
  Autosuggest,
  SpaceBetween,
  Button,
  Select,
  FormField,
  Checkbox,
} from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";
import antennaImg from "./antenna.png";

const StyleSelect = styled(Select)`
  width: 100px;
`;

const Design = () => {
  const [value1, setValue1] = React.useState("antenna");
  const [value2, setValue2] = React.useState("");

  return (
    <React.Fragment>
      <p>Modules:</p>
      <RadioGroup
        onChange={({ detail }) => setValue1(detail.value)}
        value={value1}
        items={[
          { label: "Antenna", value: "antenna" },
          { label: "Desense", value: "desense" },
        ]}
      />
      <br />
      {value1 === "desense" && (
        <Container header={<Header variant="h2">Aggressor</Header>}>
          <RadioGroup
            onChange={({ detail }) => setValue2(detail.value)}
            value={value2}
            items={[
              { value: "USB", label: "USB" },
              { value: "SDIO", label: "SDIO" },
              { value: "MIPI", label: "MIPI" },
              { value: "HDMI", label: "HDMI" },
              { value: "Touch", label: "Touch" },
              { value: "Camera", label: "Camera" },
              { value: "DDR", label: "SOC" },
              { value: "EMMC", label: "FPC" },
            ]}
          />
        </Container>
      )}

      {value1 === "antenna" && <Antenna />}
    </React.Fragment>
  );
};

export default Design;

const Antenna = () => {
  const [value, setValue] = React.useState("");
  const [selectedOption, setSelectedOption] = React.useState({
    label: "ant1",
    value: "ant1",
  });

  return (
    <React.Fragment>
      <SpaceBetween direction="horizontal" size="l">
        <SpaceBetween direction="horizontal" size="xs">
          <Autosuggest
            onChange={({ detail }) => setValue(detail.value)}
            value={value}
            options={[
              { value: "sample1.cst" },
              { value: "sample2.cst" },
              { value: "sample3.cst" },
              { value: "sample4.cst" },
            ]}
            enteredTextLabel={(value) => `Use: "${value}"`}
            ariaLabel="Autosuggest example with suggestions"
            placeholder="Select a CST input file"
            empty="No matches found"
          />
          <Button>Open CST</Button>
        </SpaceBetween>
        <Button>New CST</Button>
      </SpaceBetween>

      <br />

      <FormField label="ID">
        <StyleSelect
          selectedOption={selectedOption}
          onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
          options={[
            { label: "ant1", value: "ant1" },
            { label: "ant2", value: "ant2" },
          ]}
          selectedAriaLabel="Selected"
        />
      </FormField>
      <br />
      <div>
        <img alt="antenna" src={antennaImg} width="600px" height="350px" />
      </div>
      <br />
      <div>
        <Button>Try</Button>
      </div>
      <br />
      <div>
        <Checkbox checked={false}>Instantiate in a new CST Project</Checkbox>
        <Checkbox checked={false}>Submit for simulation</Checkbox>
      </div>
      <SpaceBetween direction="horizontal" size="m">
        <Button>Apply</Button>
      </SpaceBetween>
    </React.Fragment>
  );
};
