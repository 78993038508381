import React from "react";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react-v3/polaris";
import CollectionPreferences from "@amzn/awsui-components-react-v3/polaris/collection-preferences";
import Layout from "../../containers/layout/layout";

const mockData = [
  {
    project: "Puget",
    description: "-",
    permissions: "LDAP Group1, LDAP Group2",
  },
  {
    project: "Stinger",
    description: "-",
    permissions: "LDAP Group1, LDAP Group2",
  },
  {
    project: "Zeta",
    description: "-",
    permissions: "-",
  },
  {
    project: "Hypnos",
    description: "-",
    permissions: "-",
  },
  {
    project: "Barolo",
    description: "-",
    permissions: "-",
  },
];

const DesignProjects = () => (
  <Layout>
    <Table
      trackBy="project"
      columnDefinitions={[
        {
          id: "project",
          header: "Project Name",
          cell: (e) => e.project,
          sortingField: "project",
        },
        {
          id: "description",
          header: "Description",
          cell: (e) => e.description,
        },
        {
          id: "permissions",
          header: "Permission Groups",
          cell: (e) => e.permissions,
        },
      ]}
      items={mockData}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
      filter={
        <TextFilter filteringPlaceholder="Find resources" filteringText="" />
      }
      header={
        <Header
          counter={"(5)"}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <Button>Edit</Button>
            </SpaceBetween>
          }
        >
          Projects
        </Header>
      }
      pagination={
        <Pagination
          currentPageIndex={1}
          pagesCount={1}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            visibleContent: [
              "TaskId",
              "status",
              "value",
              "type",
              "description",
            ],
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
            ],
          }}
          visibleContentPreference={{
            title: "Select visible content",
            options: [
              {
                label: "Main distribution properties",
                options: [
                  {
                    id: "variable",
                    label: "Variable name",
                    editable: false,
                  },
                  { id: "value", label: "Text value" },
                  { id: "type", label: "Type" },
                  {
                    id: "description",
                    label: "Description",
                  },
                ],
              },
            ],
          }}
        />
      }
    />
  </Layout>
);

export default DesignProjects;
