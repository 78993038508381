import { paths } from "../../constants/route-paths";
import customerSupportConstants from "../../constants/customer-support";

export const menuItems = [];

export const helpItem = {
  text: "Help & Support",
  type: "dropdown",
  options: [
    {
      text: "Onboard Lab",
      href: customerSupportConstants.SIM_LAB_ONBOARD,
      useATag: true,
      newTab: true,
    },
    {
      text: "Report Bug",
      href: customerSupportConstants.SIM_BUG_REPORT,
      useATag: true,
      newTab: true,
    },
    {
      text: "Request Permission",
      href: customerSupportConstants.SIM_PERMISSION_REQUEST,
      useATag: true,
      newTab: true,
    },
    {
      text: "Ask Question",
      href: customerSupportConstants.SIM_GENERAL_QUESTION,
      useATag: true,
      newTab: true,
    },
    {
      text: "About US",
      href: paths.ABOUT_US,
    },
  ],
};

export const analyticsItem = {
  text: "Analytics",
  href: "/analytics",
};

export const getUserItem = (username) => ({
  text: username,
  type: "dropdown",
  options: [
    {
      text: "Sign Out",
      href: paths.SIGN_OUT,
    },
  ],
});
