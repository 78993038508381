import authnType from "../types/authn-type";

export const setAuthnState = (authnState) => ({
  type: authnType.SET_AUTHN_STATE,
  authnState,
});

export const setAuthnIDP = (authIdp) => ({
  type: authnType.SET_AUTHN_IDP,
  authIdp,
});
