import React from "react";
import { BreadcrumbGroup, Tabs } from "@amzn/awsui-components-react-v3/polaris";
import { useHistory } from "react-router-dom";
import DesignTab from "../design/index";
import SocaTab from "../cloud-desktop/index";
import FileTab from "../file-management/index";
import SimulationTab from "../job-submission/index";
import Layout from "../../containers/layout/layout";

const DesignTask = () => {
  const history = useHistory();
  const [activeTabId, setActiveTabId] = React.useState("design");

  return (
    <Layout>
      <BreadcrumbGroup
        items={[
          { text: "Design Tasks", href: "/design/tasks" },
          { text: "Task: waas1234567", href: "/design/tasks/waas1234567" },
        ]}
        ariaLabel="Breadcrumbs"
        onFollow={(e) => {
          e.preventDefault();
          history.push(e.detail.href);
        }}
      />

      <br />
      <Tabs
        activeTabId={activeTabId}
        onChange={(e) => setActiveTabId(e.detail.activeTabId)}
        tabs={[
          {
            label: "Design",
            id: "design",
          },
          {
            label: "Cloud Desktop",
            id: "soca",
          },
          {
            label: "Submit Job",
            id: "simulation",
          },
          {
            label: "File",
            id: "file",
          },
          {
            label: "Result",
            id: "result",
          },
        ]}
      />
      <div
        style={
          activeTabId === "design" ? { display: "block" } : { display: "none" }
        }
      >
        <DesignTab />
      </div>

      <div
        style={
          activeTabId === "soca" ? { display: "block" } : { display: "none" }
        }
      >
        <SocaTab />
      </div>

      <div
        style={
          activeTabId === "simulation"
            ? { display: "block" }
            : { display: "none" }
        }
      >
        <SimulationTab />
      </div>

      <div
        style={
          activeTabId === "file" ? { display: "block" } : { display: "none" }
        }
      >
        <FileTab />,
      </div>

      <div
        style={
          activeTabId === "result" ? { display: "block" } : { display: "none" }
        }
      >
        <div />
      </div>
    </Layout>
  );
};

export default DesignTask;
