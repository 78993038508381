import Auth from "@aws-amplify/auth";
import Hub from "@aws-amplify/auth";
import { config } from "../config/config";
import constants from "../config/constants";
import { authZCall } from "../redux/actions/authz-action";
import { setAuthnState, setAuthnIDP } from "../redux/actions/authn-action";
import store from "../redux/store";

export let username = undefined;
export let identityProvider = undefined;

/**
 * Redirects to the Cognito login page with the appropriate redirect URL.
 */
function redirectToLoginPage(idp) {
  store.dispatch(setAuthnState(constants.AUTHENTICATING));

  Auth.configure(config.AMPLIFY_CONFIG.oauth);
  Auth.federatedSignIn({ provider: idp, customState: window.location.href });
}

/**
 * Starts the session timer and dispatches events when session expiration
 * is approaching, as well as when session HAS expired. To determine when
 * the session will expire we use the "auth_time" + 24 hours which is when
 * the refresh token will expire (1 day).
 */
async function startSessionTimer() {
  // Values are all in seconds.
  const fiveMinutes = 60 * 5;
  const oneDay = 86400;
  const refreshTokenValidity = config.REFRESH_TOKEN_VALIDITY * oneDay;
  // Will be used to suppress further SESSION_WILL_EXPIRE events after initial event is dispatched.
  let sessionWillExpireNotice = false;
  try {
    const currentSession = await Auth.currentSession();
    const accessToken = currentSession.getAccessToken();
    const expiresTimestamp = accessToken.getIssuedAt() + refreshTokenValidity;
    setInterval(() => {
      const currentTimestamp = new Date().getTime() / 1000;
      const timeRemaining = expiresTimestamp - currentTimestamp;
      if (!sessionWillExpireNotice && timeRemaining < fiveMinutes) {
        sessionWillExpireNotice = true;
        Hub.dispatch(constants.SESSION_WILL_EXPIRE, {
          event: constants.SESSION_WILL_EXPIRE,
        });
      } else if (timeRemaining <= 0) {
        Hub.dispatch(constants.SESSION_EXPIRED, {
          event: constants.SESSION_EXPIRED,
        });
      }
    }, constants.SESSION_TIMER_INTERVAL);
  } catch (e) {
    Hub.dispatch(constants.SESSION_EXPIRED, {
      event: constants.SESSION_EXPIRED,
    });
  }
}

/**
 * Retrieves and returns the jwtToken for the currently authenticated user.
 *
 * @async
 * @returns {String} accessToken
 * Cognito jwtToken of currently authenticated user.
 */
async function getAccessToken() {
  let accessToken = "";

  try {
    await Auth.currentAuthenticatedUser().then((user) => {
      accessToken = user.signInUserSession.idToken.jwtToken;
    });
  } catch (ex) {
    // This had to be added because Amplify doesn't catch the exception, this means
    // that the user is not logged in, there is other code that redirects to login page.
  }
  return accessToken;
}

/**
 * Determines if access tokens exist (jwtToken) so that the session can be initiated.
 *
 * @async
 * @returns {Boolean} hasAccessTokens
 * Returns true if current user has access tokens already; false otherwise.
 */
async function hasAccessTokens() {
  try {
    await Auth.currentAuthenticatedUser();
  } catch (e) {
    return false;
  }

  return true;
}

/**
 * Subscribes to auth messages to handle sign in and session expiration. Also starts the session timer.
 *
 * @param {Object} sessionHandlers
 *
 * @param {Function} [sessionHandlers.onSessionExpired]
 * Invoked when session expires to handle any necessary clean up and/or user messaging.
 *
 * @param {Function} [sessionHandlers.onSessionWillExpire]
 * Invoked 5 minutes before session is set to expire to inform user that they should save any changes and reauthenticate.
 *
 * @param {Function} [sessionHandlers.onUserProfileChange]
 * Invoked when current authenticated user is resolved to update the global userProfile object in the Redux store.
 */
async function initializeSession(
  onSessionExpired,
  onSessionWillExpire,
  onUserProfileChange
) {
  await Auth.currentAuthenticatedUser().then(onUserProfileChange);

  Hub.listen(constants.SESSION_EXPIRED, onSessionExpired);
  Hub.listen(constants.SESSION_WILL_EXPIRE, () => onSessionWillExpire(true));

  setTimeout(startSessionTimer, constants.SESSION_TIMER_INTERVAL);
}

/**
 * Signs the user out and redirects back to the Cognito sign in page.
 *
 * @async
 */
async function signOut() {
  localStorage.removeItem("idp");
  await Auth.signOut();
  username = undefined;
  identityProvider = undefined;
}

export {
  // startSessionTimer,
  hasAccessTokens,
  initializeSession,
  getAccessToken,
  redirectToLoginPage,
  signOut,
};

async function getUserInfo() {
  let tokenInfo = null;
  try {
    await Auth.currentAuthenticatedUser().then((user) => {
      tokenInfo = user.getSignInUserSession().getIdToken().decodePayload();
    });
  } catch (e) {
    // This had to be added because Amplify doesn't catch the exception, this means
    // that the user is not logged in, there is other code that redirects to login page.
  }

  if (!tokenInfo) {
    return null;
  }

  // console.log(tokenInfo);
  const providerName = tokenInfo.identities[0].providerName;
  identityProvider = providerName;
  if (providerName === constants.IDP_LASSO) {
    // LASSO
    username = tokenInfo["custom:username"];
    if (username.includes("@")) {
      username = username.split("@")[0];
    }
  } else if (providerName === constants.IDP_LOGIN_WITH_AMAZON) {
    // AMZ
    username = tokenInfo.email.split("@")[0];
  } else {
    // others
    username = tokenInfo.identities[0].userId;
  }

  localStorage.setItem("idp", providerName);
  store.dispatch(setAuthnState(constants.AUTHENTICATED));
  store.dispatch(setAuthnIDP(identityProvider));
  store.dispatch(authZCall(username));

  return { username };
}

export { getUserInfo };
