import React from "react";
import { useHistory } from "react-router-dom";
import { Button, SpaceBetween, ColumnLayout, Link } from "@amzn/awsui-components-react-v3";
import { redirectToLoginPage } from "../authentication/cognito-auth";
import styled from "styled-components";
import constants from "../config/constants";
import customerSupportConstants from "../constants/customer-support";

const StyledDivTitle = styled.div`
  height: 100px;
`;

const SignIn = () => {
  const history = useHistory();

  const onClickSignIn = (idp) => {
    history.push("/");
    redirectToLoginPage(idp);
  };

  return (
    <ColumnLayout columns={2} variant="text-grid">
      {/* col 1 */}
      <SpaceBetween direction="vertical" size="xs">
        <StyledDivTitle>Sign In For Amazon Employees and Contractors</StyledDivTitle>
        <Button
          variant="normal"
          className="app-button"
          onClick={() => onClickSignIn(constants.IDP_AMAZON_FEDERATE)}
        >
          {/* Cognito + Amazon Federate */}
          Continue with Midway
        </Button>
      </SpaceBetween>

      {/* col 2*/}
      <SpaceBetween direction="vertical" size="xs">
        <StyledDivTitle>
          <p>Sign In For Lab Users</p>
          <Link
            external
            externalIconAriaLabel="Opens in a new tab"
            href={customerSupportConstants.SIM_LAB_ONBOARD}
          >
            Do not have an account?
          </Link>
        </StyledDivTitle>
        <Button
          variant="normal"
          className="app-button"
          onClick={() => {
            onClickSignIn(constants.IDP_LASSO);
          }}
        >
          Continue with LASSO
          {/* Cognito + LASSO */}
        </Button>
      </SpaceBetween>
    </ColumnLayout>
  );
};

export default SignIn;
