import { withRouter } from "react-router-dom";
import {
  SideNavigation,
  SideNavigationProps,
} from "@amzn/awsui-components-react-v3/polaris";
import { designConfig } from "./sidebar-config/sidebar-config-design";
import { getTestConfig } from "./sidebar-config/sidebar-config-test";
import constants from "../../config/constants";
import { useSelector } from "react-redux";

const Sidebar = ({ location, history }) => {
  const { authzReducer } = useSelector((state: any) => ({
    authzReducer: state.authzReducer,
  }));

  const permissionObj = authzReducer[constants.AUTH_PERMISSION_KEY];

  const onFollow = (e) => {
    if (!e.detail.external) {
      e.preventDefault();
      history.push(e.detail.href);
    }
  };

  const getSidebarConfig = () => {
    if (location.pathname.includes("/design")) {
      return designConfig;
    }

    return getTestConfig(permissionObj[constants.AUTH_USERTYPE_KEY]);
  };

  const sidebarConfig = getSidebarConfig();

  return (
    <SideNavigation
      header={{ href: sidebarConfig.headerUrl, text: sidebarConfig.headerText }}
      activeHref={location.pathname || "/"}
      onFollow={onFollow}
      items={sidebarConfig.sidebarItems as SideNavigationProps.Item[]}
    />
  );
};

export default withRouter(Sidebar);
