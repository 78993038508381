import React from "react";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination,
  SpaceBetween,
  Button,
} from "@amzn/awsui-components-react-v3/polaris";
import { NavLink } from "react-router-dom";
import CollectionPreferences from "@amzn/awsui-components-react-v3/polaris/collection-preferences";
import Layout from "../../containers/layout/layout";

const mockData = [
  {
    taskId: "waas1234567",
    name: "test task for puget",
    project: "Puget",
    create_time: "2021-12-11 12:23:56",
    update_time: "2021-12-25 12:23:56",
    description: "This is the first task",
  },
  {
    taskId: "waas1234568",
    name: "test task for stinger1",
    project: "Stinger",
    create_time: "2021-12-15 12:23:56",
    update_time: "2021-12-18 12:23:56",
    description: "This is the second task",
  },
  {
    taskId: "waas1234569",
    name: "test task for stinger2",
    project: "Stinger",
    create_time: "2021-12-15 12:23:56",
    update_time: "2021-12-17 12:23:56",
    description: "-",
  },
  {
    taskId: "waas1234506",
    name: "test task for zeta",
    project: "Zeta",
    create_time: "2021-12-11 12:23:56",
    update_time: "2021-12-12 16:23:56",
    description: "This is the fourth task",
  },
  {
    taskId: "waas1234510",
    name: "test task for Barolo",
    project: "Barolo",
    create_time: "2021-11-23 12:23:56",
    update_time: "2021-11-31 12:23:56",
    description: "This is the fifth task with a longer description",
  },
];

const DesignTasks = () => {
  return (
    <Layout>
      <Table
        trackBy="taskId"
        columnDefinitions={[
          {
            id: "taskId",
            header: "Task ID",
            cell: (e) => (
              <NavLink to="/design/tasks/waas1234567">{e.taskId}</NavLink>
            ),
            sortingField: "taskId",
          },
          {
            id: "name",
            header: "Task Name",
            cell: (e) => e.name,
            sortingField: "name",
          },
          {
            id: "project",
            header: "Project",
            cell: (e) => e.project,
            sortingField: "project",
          },
          {
            id: "create_time",
            header: "Create At",
            cell: (e) => e.create_time,
            sortingField: "create_time",
          },
          {
            id: "update_time",
            header: "Update At",
            cell: (e) => e.update_time,
            sortingField: "update_time",
          },
          {
            id: "description",
            header: "Description",
            cell: (e) => e.description,
          },
        ]}
        items={mockData}
        loadingText="Loading resources"
        empty={
          <Box textAlign="center" color="inherit">
            <b>No resources</b>
            <Box padding={{ bottom: "s" }} variant="p" color="inherit">
              No resources to display.
            </Box>
          </Box>
        }
        filter={
          <TextFilter filteringPlaceholder="Find resources" filteringText="" />
        }
        header={
          <Header
            counter={"(5)"}
            actions={
              <SpaceBetween direction="horizontal" size="xs">
                <Button>Edit</Button>
                <Button variant="primary">Create Task</Button>
              </SpaceBetween>
            }
          >
            Design Tasks
          </Header>
        }
        pagination={
          <Pagination
            currentPageIndex={1}
            pagesCount={1}
            ariaLabels={{
              nextPageLabel: "Next page",
              previousPageLabel: "Previous page",
              pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
            }}
          />
        }
        preferences={
          <CollectionPreferences
            title="Preferences"
            confirmLabel="Confirm"
            cancelLabel="Cancel"
            preferences={{
              pageSize: 10,
              visibleContent: [
                "TaskId",
                "status",
                "value",
                "type",
                "description",
              ],
            }}
            pageSizePreference={{
              title: "Select page size",
              options: [
                { value: 10, label: "10 resources" },
                { value: 20, label: "20 resources" },
              ],
            }}
            visibleContentPreference={{
              title: "Select visible content",
              options: [
                {
                  label: "Main distribution properties",
                  options: [
                    {
                      id: "variable",
                      label: "Variable name",
                      editable: false,
                    },
                    { id: "value", label: "Text value" },
                    { id: "type", label: "Type" },
                    {
                      id: "description",
                      label: "Description",
                    },
                  ],
                },
              ],
            }}
          />
        }
      />
    </Layout>
  );
};

export default DesignTasks;
