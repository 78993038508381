import React, { Component } from "react";
import Layout from "../layout/layout";
import customerSupportConstants from "../../constants/customer-support";

const containerStyle = {
  textAlign: "center" as const,
  marginTop: "80px",
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center" as const,
  alignItems: "center" as const,
};

const titleStyle = {
  fontSize: "40px",
  fontWeight: 700,
};

const textStyle = {
  fontSize: "16px",
  marginTop: "20px",
  width: "800px",
};

class NotAuthz extends Component {
  render() {
    return (
      <Layout>
        <div style={containerStyle}>
          <div style={titleStyle}>Not Authorized</div>
          <br />
          <div style={textStyle}>
            Sorry, you don't have the access to the website. To request the access, please create a
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={customerSupportConstants.SIM_PERMISSION_REQUEST}
            >
              &nbsp;SIM ticket&nbsp;
            </a>
            to WISe team.
          </div>
          <div style={textStyle}>
            If you are a lab user and want to onboard your lab info, please use this link
            <a
              target="_blank"
              rel="noopener noreferrer"
              href={customerSupportConstants.SIM_LAB_ONBOARD}
            >
              &nbsp;Lab Onboarding portal&nbsp;
            </a>
          </div>
          <div style={textStyle}></div>
        </div>
      </Layout>
    );
  }
}

export default NotAuthz;
