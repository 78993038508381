import React, { useState, useEffect } from "react";
import {
  Container,
  Header,
  Form,
  Button,
  Alert,
  SpaceBetween,
  Select,
} from "@amzn/awsui-components-react-v3";
import UploadFileSupport from "../file-explanation/file-support-explanation";
import UploadFileDropzone from "./file-dropzone";
import UploadingModal from "./uploading-modal";
import { func } from "prop-types";
// ajax
import { fileNameCheckCall, fileUploadS3Call } from "./ajax";
// constans & interface
import constants from "../../../config/constants";
import { FileStatus, UploadItem } from "../interface";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import querySearch from "query-string";
import { graphqlCall } from "../../../utils/graphql-api";
import {
  getLabInfo,
  formatLabList,
  getAssignmentIdList,
  getRequestIdbyAssignId,
  updateQueryStringParameter,
  queryStringIdExist,
} from "./upload-config";
import { getLabAssignments } from "../../../redux/actions/assignment-action";
import styled from "styled-components";

const StyleSelect = styled(Select)`
  min-width: 80px;
`;

const labelStyle = { fontSize: "1.5rem", fontWeight: 500 };

const UploadPanel = ({ lensProjectCodename, getUploadHistory }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { authnReducer, labAssignmentReducer, authzReducer } = useSelector(
    (state: any) => ({
      authnReducer: state.authnReducer,
      labAssignmentReducer: state.labAssignmentReducer,
      authzReducer: state.authzReducer,
    })
  );
  const [uploadItems, setUploadItems] = useState<Array<UploadItem>>([]);
  const [showModal, setShowModal] = useState(false);
  const [labList, setLabList] = useState<Array<String> | null>(null);

  // if user is IDP_AMAZON_FEDERATE then request lab list and enable select
  const isAmzInternalUser =
    constants.IDP_AMAZON_FEDERATE === authnReducer.authIdp ? true : false;
  // permission value for user
  const userPermissionObj = authzReducer.permission;
  const labAssignData = labAssignmentReducer.data;
  // console.log(labAssignData);

  const queryString = querySearch.parse(history.location.search);
  const assignmentId = queryString.assignment_id
    ? queryString.assignment_id
    : undefined;
  const labId = queryString.lab_id ? queryString.lab_id : undefined;

  const [currLab, setCurrLab] = useState({});
  const [currAssign, setCurrAssignment] = useState({});

  // event: when on drag and drop file in dropzone
  const _onChangeFiles = (files: Array<File>) => {
    setUploadItems((oldUploadItems) => {
      const newUploadItems = [...oldUploadItems];
      files.forEach((file: File) => {
        if (!newUploadItems.some((item) => item.filename === file.name)) {
          newUploadItems.push({
            file,
            filename: file.name,
            fileCheckStatus: constants.LOADING_LOAD,
            uploadStatus: constants.LOADING_LOAD,
            message: "",
          });
        }
      });
      return newUploadItems;
    });
  };

  // loop each file, and send ajxa call to validate file name
  useEffect(() => {
    if (uploadItems.length > 0) {
      uploadItems.forEach(({ file, fileCheckStatus }) => {
        if (fileCheckStatus === constants.LOADING_LOAD) {
          fileNameCheckCall(file, lensProjectCodename).then(
            (rst: FileStatus) => {
              setUploadItems((oldUploadItems) => {
                const newUploadItems = [...oldUploadItems];
                newUploadItems.forEach((item) => {
                  if (file.name === item.filename) {
                    item.fileCheckStatus = rst.status;
                    item.message = rst.message;
                  }
                });
                return newUploadItems;
              });
            }
          );
        }
      });
    }
  }, [uploadItems, lensProjectCodename]);

  // get lab list if isAmzInternalUser is true
  useEffect(() => {
    const labUserLabInfo = userPermissionObj.userLab;

    const labsQuery = `
    query {
      labs {
        id
        name
      }
    }
  `;
    graphqlCall(labsQuery).then(({ json, ok }) => {
      if (ok && json.labs) {
        setLabList(json.labs);
        if (!isAmzInternalUser && labId === undefined) {
          setCurrLab({
            label: labUserLabInfo,
            value: labUserLabInfo,
          });
        } else {
          setCurrLab({
            label: getLabInfo(labId, json.labs),
            value: getLabInfo(labId, json.labs),
          });
        }
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // get assignment list once user update lab
  useEffect(() => {
    if (currLab && labList) {
      const currLabObj = labList.find(
        (eachObj) => eachObj["name"] === currLab["value"]
      );
      currLabObj && dispatch(getLabAssignments(Number(currLabObj["id"])));
    }
    if (
      labAssignmentReducer.loadingStatus === constants.LOADING_SUCCESS &&
      labAssignData.length === 0
    ) {
      const updatedPath = updateQueryStringParameter(
        history.location.search,
        "assignment_id",
        -1
      );
      history.push(`${history.location.pathname}${updatedPath}`);
      setCurrAssignment({
        label: "Empty",
        value: "Empty",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currLab]);

  /*
        loop each file, 
        1. get presigned url
        2. the upload to s3
       */
  const _uploadFiles = async () => {
    const currMetaData = {
      lab_id: labId.toString(),
      request_id: getRequestIdbyAssignId(
        labAssignData,
        assignmentId
      ).toString(),
    };
    uploadItems.forEach(({ file }) => {
      fileUploadS3Call(file, lensProjectCodename, currMetaData).then(
        (rst: FileStatus) => {
          setUploadItems((oldUploadItems) => {
            const newUploadItems = [...oldUploadItems];
            newUploadItems.forEach((item) => {
              if (file.name === item.filename) {
                item.uploadStatus = rst.status;
                item.message = rst.message;
              }
            });
            return newUploadItems;
          });
        }
      );
    });
  };

  // onClick clear button
  const _onClear = () => {
    setUploadItems([]);
    setShowModal(false);
  };

  // delete a file
  const _deleteFile = (filename: string) => {
    setUploadItems((oldUploadItems) => {
      const newUploadItems = [...oldUploadItems].filter(
        (item) => item.filename !== filename
      );
      return newUploadItems;
    });
  };

  /* 
        check file name validation in fileCheckList
        return false if any of the file name is invalid
        return true if all file names are valid
      */
  // console.log(queryStringIdExist(labId, assignmentId, labList, labAssignData));
  const _validateForm = () => {
    if (
      uploadItems.some(
        (item) => item.fileCheckStatus === constants.LOADING_FAIL
      ) &&
      queryStringIdExist(labId, assignmentId, labList, labAssignData)
    ) {
      return false;
    }
    return true;
  };

  const _showModal = () => {
    setShowModal(true);
  };

  const _closeModal = () => {
    setShowModal(false);

    // clear form
    _onClear();

    // refetch upload status data, after 3s, give parse time to write log into db
    // tmp solution
    setTimeout(() => getUploadHistory(true), 3000);
  };

  const _onChangeLab = ({ detail: { selectedOption } }) => {
    setCurrLab(selectedOption);
    const currId = selectedOption.id;

    const updatedPath = updateQueryStringParameter(
      history.location.search,
      "lab_id",
      currId
    );
    history.push(`${history.location.pathname}${updatedPath}`);
  };

  const _onChangeAssignment = ({ detail: { selectedOption } }) => {
    setCurrAssignment(selectedOption);
    const currVal = selectedOption.value;
    const updatedPath = updateQueryStringParameter(
      history.location.search,
      "assignment_id",
      currVal
    );
    // updatedPath.push(currVal);
    history.push(`${history.location.pathname}${updatedPath}`);
  };

  /*
        onClick Upload button
        1. fileList is empty => alert
        2. validate file name failed => alert
        3. all good => confirm
            3.1 no => clear and return
            3.2 yes => open model, start upload process
      */
  const _onClickUpload = () => {
    // 1. fileList is empty
    if (uploadItems.length === 0) {
      alert("No file was uploaded.");
      return;
    }

    // 2. validate file name failed
    if (!_validateForm()) {
      alert("Invalid file name.");
      return;
    }

    // 3. all good, confirm
    let isConfirmed = window.confirm(
      "Files will be uploaded LENS. Click OK to confirm. "
    );
    if (!isConfirmed) {
      return;
    }

    _showModal();

    _uploadFiles();
  };

  /* 
        check if need to disable upload button
        case 1: fileCheckList is empty
        case 2: Any file name is checking or failed
      */
  const _checkDisableUploadButton = () => {
    // case 1:
    if (uploadItems.length === 0) {
      return true;
    }

    // case 2:
    if (
      uploadItems.some(
        (items) => items.fileCheckStatus !== constants.LOADING_SUCCESS
      )
    ) {
      return true;
    }

    // otherwise, do not disable, return false
    return false;
  };

  const assignmentDetailSelection = (
    <SpaceBetween direction="horizontal" size="m">
      <SpaceBetween direction="horizontal" size="m">
        <span style={labelStyle}>Lab: </span>
        <StyleSelect
          selectedOption={
            Object.keys(currLab).length > 0
              ? currLab
              : {
                  label: getLabInfo(labId, labList),
                  value: getLabInfo(labId, labList),
                }
          }
          options={formatLabList(labList)}
          onChange={_onChangeLab}
          disabled={isAmzInternalUser ? false : true}
          selectedAriaLabel="Selected"
          filteringType="auto"
        />
      </SpaceBetween>
      <SpaceBetween direction="horizontal" size="m">
        <span style={labelStyle}>Assignment ID: </span>
        <StyleSelect
          selectedOption={
            Object.keys(currAssign).length > 0
              ? currAssign
              : {
                  label: assignmentId,
                  value: assignmentId,
                }
          }
          onChange={_onChangeAssignment}
          options={getAssignmentIdList(labAssignData).map((eachId) => ({
            label: eachId.toString(),
            value: eachId.toString(),
          }))}
          selectedAriaLabel="Selected"
          statusType={
            labAssignmentReducer.loadingStatus === constants.LOADING_LOAD
              ? "loading"
              : "finished"
          }
          filteringType="auto"
        />
      </SpaceBetween>
    </SpaceBetween>
  );

  return (
    <Container
      header={
        <Header variant="h2" actions={assignmentDetailSelection}>
          Upload File
        </Header>
      }
    >
      {/* file name support info */}
      <UploadFileSupport />
      <br />

      {/* main upload portion */}
      <Form
        header={<Header description="Drag and drop to upload files"></Header>}
      >
        <SpaceBetween direction="vertical" size="m">
          {!_validateForm() && (
            <Alert header="Errors Detected" type="error">
              Please check you file name, and try again.
            </Alert>
          )}
          <UploadFileDropzone
            uploadItems={uploadItems}
            onChange={_onChangeFiles}
            deleteFile={_deleteFile}
          />
        </SpaceBetween>
      </Form>
      <br />

      {/* action stripe group */}
      <SpaceBetween direction="horizontal" size="xs">
        <Button onClick={_onClear}>Clear</Button>
        <Button
          variant="primary"
          onClick={_onClickUpload}
          disabled={_checkDisableUploadButton()}
        >
          Upload
        </Button>
      </SpaceBetween>

      <UploadingModal
        showModal={showModal}
        onDismiss={_onClear}
        onClose={_closeModal}
        uploadItems={uploadItems}
      />
    </Container>
  );
};

UploadPanel.prototype = {
  getUploadHistory: func,
};

export default UploadPanel;
