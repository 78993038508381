export const TEST_PATH_PREFIX = "/test";
export const DESIGN_PATH_PREFIX = "/design";

// common
class Paths {
  LANDING = "/landing";
  SIGN_IN = "/signin";
  SIGN_OUT = "/signout";
  ABOUT_US = "/about-us";
  NOT_FOUND = "/not-found";
  NO_AUTHZ = "/no-authz";
}

class TestPaths {
  REQEST_FORM = `${TEST_PATH_PREFIX}/request-form`;
  REQEST_STATUS = `${TEST_PATH_PREFIX}/request-status`;
  LAB = `${TEST_PATH_PREFIX}/third-party-lab`;

  LAB_ASSIGNMENT = `${TEST_PATH_PREFIX}/third-party-lab/:labId/assignments/:assignment/`;
  getLabAssignmentPath = (labId, assignmentId) =>
    `${TEST_PATH_PREFIX}/third-party-lab/${labId}/assignments/${assignmentId}`;

  LAB_TEST_CATEGORY = `${TEST_PATH_PREFIX}/third-party-lab/:labId/assignments/:assignment/test_categories/:test_category`;
  getLabTestCategoryPath = (labId, assignmentId, testCategoryId) =>
    `${TEST_PATH_PREFIX}/third-party-lab/${labId}/assignments/${assignmentId}/test_categories/${testCategoryId}`;

  REQUEST_DETAIL = `${TEST_PATH_PREFIX}/:assignment_id/:dsn/:lab/request-detail`;
  getRequestDetail = (assignmentId, dsn, labId) =>
    `${TEST_PATH_PREFIX}/${assignmentId}/${dsn}/${labId}/request-detail`;

  PRE_UPLOAD = `${TEST_PATH_PREFIX}/upload`;
  UPLOAD = `${TEST_PATH_PREFIX}/upload/:lensProjectCodename`;
  UPLOAD_ENGINEERING = `${TEST_PATH_PREFIX}/upload/engineering`;

  NOT_FOUND = `${TEST_PATH_PREFIX}/not-found`;
}

class DesignPaths {
  DESIGN_TASKS = `${DESIGN_PATH_PREFIX}/tasks`;

  DESIGN_TASK = `${DESIGN_PATH_PREFIX}/tasks/:id`;
  getDesignTaskPath = (taskId) => `${DESIGN_PATH_PREFIX}/tasks/${taskId}`;

  PROJECTS = `${DESIGN_PATH_PREFIX}/projects`;

  NOT_FOUND = `${DESIGN_PATH_PREFIX}/not-found`;
}

export const paths = new Paths();
export const testPaths = new TestPaths();
export const designPaths = new DesignPaths();
