import { testPaths } from "./../../../constants/route-paths";

export const Breadcrumb = (assignmentId) => {
  return [
    { text: "Home", href: "/" },
    { text: "Third Party Lab", href: testPaths.LAB },
    {
      text: "Assignment : " + assignmentId,
      href: testPaths.LAB_ASSIGNMENT,
    },
  ];
};

export const test_case_sample_data = [];
