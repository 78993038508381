import React from "react";
import socaImg from "./soca.png";

const CloudDesktop = () => (
  <div>
    <img alt="soca" src={socaImg} width="1200px" />
  </div>
);

export default CloudDesktop;
