import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import Amplify, { Hub } from "@aws-amplify/core";
import { redirectToLoginPage } from "./authentication/cognito-auth";
import { useDispatch, useSelector } from "react-redux";
import { setAuthnState } from "./redux/actions/authn-action";
import { getUserInfo } from "./authentication/cognito-auth";
import { config } from "./config/config";
import constants from "./config/constants";
import Router from "./router";
import { Spinner } from "@amzn/awsui-components-react-v3";
import { RunningMan } from "./components/loading-indicators";
import { paths } from "./constants/route-paths";
import "./App.css";

const App = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  // redux
  const { authnReducer, authzReducer } = useSelector((state) => ({
    authnReducer: state.authnReducer,
    authzReducer: state.authzReducer,
  }));
  const { authnState } = authnReducer;
  const { authz } = authzReducer;

  // state
  const [verifyingToken, setVerifyingToken] = useState(true);

  // Amplify Setup
  Amplify.configure(config.AMPLIFY_CONFIG);
  // cognito login listener
  Hub.listen("auth", ({ payload: { event, data } }) => {
    switch (event) {
      case constants.SIGNIN:
        console.log("signin...");
        break;
      case constants.SIGNIN_FAILURE:
        console.error("SignIn failed");
        dispatch(setAuthnState(constants.SIGNIN_FAILURE));
        break;
      case constants.SIGNOUT:
        console.log("signout...");
        break;
      case constants.CUSTOM_OAUTH_STATE:
        window.location.href = decodeURIComponent(data);
        break;
      default:
        return;
    }
  });

  // 1) cognito authorization code steps
  useEffect(() => {
    if (authnState === constants.UNAUTHENTICATED) {
      setVerifyingToken(true);
      setTimeout(
        () => {
          getUserInfo().then((userInfo) => {
            setVerifyingToken(false);
            // token not valid
            if (!userInfo) {
              // check idp in local storage
              const idp = localStorage.getItem("idp");
              // a. yes, use idp to signin
              if (idp && [constants.IDP_AMAZON_FEDERATE, constants.IDP_LASSO].includes(idp)) {
                redirectToLoginPage(idp);
              }
              // b. no, redirect to signin page
              else {
                history.push(paths.SIGN_IN);
              }
            }
          });
        },
        new URLSearchParams(window.location.search).has("code") ? 3000 : 500
      );
    }
  }, [authnState, history, dispatch]);

  if (verifyingToken) {
    return null;
  }

  //  authenticating
  if (authnState === constants.AUTHENTICATING) {
    return (
      <div className={"app-loading-div"} style={{ color: "black" }}>
        <Spinner />
        Authenticating...
        <RunningMan />
      </div>
    );
  }

  // aunthentication failed
  if (authnState === constants.SIGNIN_FAILURE) {
    return <div style={{ color: "black" }}>Logging failed</div>;
  }

  if (authnState === constants.AUTHENTICATED && authz === 0) {
    return (
      <div className={"app-loading-div"} style={{ color: "black" }}>
        <Spinner />
        Authorizating..
        <RunningMan />
      </div>
    );
  }

  // unauthenticated, authenticated && authorized
  return <Router />;
};

export default App;
