import React from "react";
import {
  Table,
  Box,
  TextFilter,
  Header,
  Pagination,
  SpaceBetween,
  Button,
  ButtonDropdown,
  RadioGroup,
  Icon,
} from "@amzn/awsui-components-react-v3/polaris";
import CollectionPreferences from "@amzn/awsui-components-react-v3/polaris/collection-preferences";

const FileManagement = () => {
  const [value1, setValue1] = React.useState("project");
  return (
    <React.Fragment>
      <RadioGroup
        onChange={({ detail }) => setValue1(detail.value)}
        value={value1}
        items={[
          { label: "Files in the project", value: "project" },
          { label: "Files in the task", value: "task" },
        ]}
      />
      <br />
      {value1 === "project" && <FileProject />}
      {value1 === "task" && <FileTask />}
    </React.Fragment>
  );
};

export default FileManagement;

const FileProject = () => {
  return (
    <Table
      trackBy="taskId"
      columnDefinitions={[
        {
          id: "name",
          header: "Name",
          cell: (e) => (
            <span>
              {" "}
              {e.type === "Folder" ? (
                <Icon name="folder" size="normal" variant="normal" />
              ) : (
                <Icon name="file" size="normal" variant="normal" />
              )}{" "}
              &nbsp; {e.name}
            </span>
          ),
          sortingField: "name",
        },
        {
          id: "type",
          header: "Type",
          cell: (e) => e.type,
          sortingField: "type",
        },
        {
          id: "size",
          header: "Size",
          cell: (e) => e.size,
        },
        {
          id: "create_time",
          header: "Create At",
          cell: (e) => e.create_time,
          sortingField: "create_time",
        },
        {
          id: "update_time",
          header: "Last Modified",
          cell: (e) => e.update_time,
          sortingField: "update_time",
        },
      ]}
      items={[
        {
          name: "Design_Guidence.pdf",
          type: "pdf",
          size: "12 MB",
          create_time: "2021-12-11 9:23:56",
          update_time: "2021-12-25 12:23:56",
        },
        {
          name: "Reports",
          type: "Folder",
          size: "-",
          create_time: "2021-12-10 8:23:56",
          update_time: "2021-12-24 14:23:56",
        },
        {
          name: "tryout_cst1.cst",
          type: "cst",
          size: "100 KB",
          create_time: "2021-12-10 12:23:56",
          update_time: "2021-12-20 12:23:56",
        },
        {
          name: "tryout_cst2.cst",
          type: "cst",
          size: "120 KB",
          create_time: "2021-12-5 12:23:56",
          update_time: "2021-12-18 12:23:56",
        },
      ]}
      loadingText="Loading resources"
      empty={
        <Box textAlign="center" color="inherit">
          <b>No resources</b>
          <Box padding={{ bottom: "s" }} variant="p" color="inherit">
            No resources to display.
          </Box>
        </Box>
      }
      filter={
        <TextFilter filteringPlaceholder="Find resources" filteringText="" />
      }
      header={
        <Header
          counter={"(4)"}
          actions={
            <SpaceBetween direction="horizontal" size="xs">
              <ButtonDropdown
                items={[{ text: "Delete", id: "rm", disabled: false }]}
              >
                Actions
              </ButtonDropdown>
              <Button variant="primary">Create Folder</Button>
              <Button variant="primary">Upload</Button>
            </SpaceBetween>
          }
        >
          Files
        </Header>
      }
      selectionType="single"
      pagination={
        <Pagination
          currentPageIndex={1}
          pagesCount={1}
          ariaLabels={{
            nextPageLabel: "Next page",
            previousPageLabel: "Previous page",
            pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
          }}
        />
      }
      preferences={
        <CollectionPreferences
          title="Preferences"
          confirmLabel="Confirm"
          cancelLabel="Cancel"
          preferences={{
            pageSize: 10,
            visibleContent: [
              "TaskId",
              "status",
              "value",
              "type",
              "description",
            ],
          }}
          pageSizePreference={{
            title: "Select page size",
            options: [
              { value: 10, label: "10 resources" },
              { value: 20, label: "20 resources" },
            ],
          }}
          visibleContentPreference={{
            title: "Select visible content",
            options: [
              {
                label: "Main distribution properties",
                options: [
                  {
                    id: "variable",
                    label: "Variable name",
                    editable: false,
                  },
                  { id: "value", label: "Text value" },
                  { id: "type", label: "Type" },
                  {
                    id: "description",
                    label: "Description",
                  },
                ],
              },
            ],
          }}
        />
      }
    />
  );
};

const FileTask = () => (
  <Table
    trackBy="taskId"
    columnDefinitions={[
      {
        id: "name",
        header: "Name",
        cell: (e) => (
          <span>
            {" "}
            {e.type === "Folder" ? (
              <Icon name="folder" size="normal" variant="normal" />
            ) : (
              <Icon name="file" size="normal" variant="normal" />
            )}{" "}
            &nbsp; {e.name}
          </span>
        ),
        sortingField: "name",
      },
      {
        id: "type",
        header: "Type",
        cell: (e) => e.type,
        sortingField: "type",
      },
      {
        id: "size",
        header: "Size",
        cell: (e) => e.size,
      },
      {
        id: "create_time",
        header: "Create At",
        cell: (e) => e.create_time,
        sortingField: "create_time",
      },
      {
        id: "update_time",
        header: "Last Modified",
        cell: (e) => e.update_time,
        sortingField: "update_time",
      },
    ]}
    items={[
      {
        name: "tmp.txt",
        type: "txt",
        size: "12 KB",
        create_time: "2021-12-11 9:23:56",
        update_time: "2021-12-25 12:23:56",
      },
      {
        name: "task1.cst",
        type: "cst",
        size: "100 KB",
        create_time: "2021-12-10 12:23:56",
        update_time: "2021-12-20 12:23:56",
      },
      {
        name: "task2.cst",
        type: "cst",
        size: "120 KB",
        create_time: "2021-12-5 12:23:56",
        update_time: "2021-12-18 12:23:56",
      },
    ]}
    loadingText="Loading resources"
    empty={
      <Box textAlign="center" color="inherit">
        <b>No resources</b>
        <Box padding={{ bottom: "s" }} variant="p" color="inherit">
          No resources to display.
        </Box>
      </Box>
    }
    filter={
      <TextFilter filteringPlaceholder="Find resources" filteringText="" />
    }
    header={
      <Header
        counter={"(3)"}
        actions={
          <SpaceBetween direction="horizontal" size="xs">
            <ButtonDropdown
              items={[{ text: "Delete", id: "rm", disabled: false }]}
            >
              Actions
            </ButtonDropdown>
            <Button variant="primary">Create Folder</Button>
            <Button variant="primary">Upload</Button>
          </SpaceBetween>
        }
      >
        Files
      </Header>
    }
    selectionType="single"
    pagination={
      <Pagination
        currentPageIndex={1}
        pagesCount={1}
        ariaLabels={{
          nextPageLabel: "Next page",
          previousPageLabel: "Previous page",
          pageLabel: (pageNumber) => `Page ${pageNumber} of all pages`,
        }}
      />
    }
    preferences={
      <CollectionPreferences
        title="Preferences"
        confirmLabel="Confirm"
        cancelLabel="Cancel"
        preferences={{
          pageSize: 10,
          visibleContent: ["TaskId", "status", "value", "type", "description"],
        }}
        pageSizePreference={{
          title: "Select page size",
          options: [
            { value: 10, label: "10 resources" },
            { value: 20, label: "20 resources" },
          ],
        }}
        visibleContentPreference={{
          title: "Select visible content",
          options: [
            {
              label: "Main distribution properties",
              options: [
                {
                  id: "variable",
                  label: "Variable name",
                  editable: false,
                },
                { id: "value", label: "Text value" },
                { id: "type", label: "Type" },
                {
                  id: "description",
                  label: "Description",
                },
              ],
            },
          ],
        }}
      />
    }
  />
);
