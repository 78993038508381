import React from "react";
import {
  Autosuggest,
  SpaceBetween,
  Button,
  FormField,
  Select,
} from "@amzn/awsui-components-react-v3/polaris";
import styled from "styled-components";

const StyleSelect = styled(Select)`
  width: 200px;
`;

const JobSubmission = () => (
  <div>
    <SpaceBetween direction="vertical" size="l">
      <Item index="1" />
      <Item index="2" />
    </SpaceBetween>
    <br />
    <Button>Add New Simulation Job</Button>
  </div>
);

export default JobSubmission;

const Item = (props) => {
  const [value, setValue] = React.useState("sample1.cst");
  const [selectedOption, setSelectedOption] = React.useState({
    label: "Transient Solver",
    value: "Transient Solver",
  });
  return (
    <SpaceBetween direction="vertical" size="s">
      <p>Simulation Job {props.index}</p>
      <SpaceBetween direction="horizontal" size="m">
        <FormField label="CST Input File">
          <Autosuggest
            onChange={({ detail }) => setValue(detail.value)}
            value={value}
            options={[
              { value: "sample1.cst" },
              { value: "sample2.cst" },
              { value: "sample3.cst" },
              { value: "sample4.cst" },
            ]}
            enteredTextLabel={(value) => `Use: "${value}"`}
            ariaLabel="Autosuggest example with suggestions"
            placeholder="Select a CST input file"
            empty="No matches found"
          />
        </FormField>

        <FormField label="Solver">
          <StyleSelect
            selectedOption={selectedOption}
            onChange={({ detail }) => setSelectedOption(detail.selectedOption)}
            options={[
              { label: "Transient Solver", value: "Transient Solver" },
              { label: "Parameter Sweep", value: "Parameter Sweep" },
              { label: "Optimizer", value: "Optimizer" },
            ]}
            selectedAriaLabel="Selected"
          />
        </FormField>
      </SpaceBetween>
      <Button>Remove</Button>
      <hr />
    </SpaceBetween>
  );
};
