import { testPaths } from "./../../../constants/route-paths";

export const Breadcrumb = (labId, assignmentId, testCategoryId) => {
  return [
    { text: "Home", href: "/" },
    { text: "Third Party Lab", href: testPaths.LAB },
    {
      text: "Assignment : " + assignmentId,
      href: testPaths.getLabAssignmentPath(labId, assignmentId),
    },
    {
      text: "Test Category : " + testCategoryId,
      href: testPaths.getLabTestCategoryPath(
        labId,
        assignmentId,
        testCategoryId
      ),
    },
  ];
};
