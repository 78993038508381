import React from "react";
import { AppLayout } from "@amzn/awsui-components-react-v3";
import { useLocation } from "react-router-dom";
import Navbar from "../navbar/navbar";
import Sidebar from "../sidebar/sidebar";
import { Footer } from "@amzn/lens-react-ui";
import "./layout.css";
import {
  testPaths,
  TEST_PATH_PREFIX,
  DESIGN_PATH_PREFIX,
} from "../../constants/route-paths";

const SIDEBAR_WIDTH = 200;

const Layout = (props) => {
  const location = useLocation();
  const [sidebarWidth, setSidebarWidth] = React.useState(SIDEBAR_WIDTH);

  /*
  if the pathname is for common upload except the upload for WaaS,
  hide the sidebar.
  */
  React.useEffect(() => {
    // Not show sidebar if:
    // a) upload page for other initiatives than engineering/waas
    if (
      location.pathname.includes("/upload") &&
      !location.pathname.includes(testPaths.UPLOAD_ENGINEERING)
    ) {
      return setSidebarWidth(0);
    }

    // b) path not contain /test or /design
    if (
      !location.pathname.includes(TEST_PATH_PREFIX) &&
      !location.pathname.includes(DESIGN_PATH_PREFIX)
    ) {
      return setSidebarWidth(0);
    }

    setSidebarWidth(SIDEBAR_WIDTH);
  }, [location.pathname]);

  return (
    <div>
      <Navbar />
      <AppLayout
        id="custom-main-layout"
        content={
          <React.Fragment>
            <div className="main-content">{props.children}</div>
            <Footer />
          </React.Fragment>
        }
        navigation={<Sidebar />}
        toolsHide={true}
        navigationWidth={sidebarWidth}
      />
    </div>
  );
};

export default Layout;
