import { Component } from "react";
import BreadcrumbGroup from "@amzn/awsui-components-react/polaris/breadcrumb-group";
import { Breadcrumb } from "./test-categories-config";
import Layout from "../../layout/layout";
import { Dispatch } from "redux";
import { getAssignmentTestCategories } from "../../../redux/actions/assignment-action";
import { connect } from "react-redux";
import TestCategoriesTable from "./test-categories-table/table";
import { RouteComponentProps, withRouter } from "react-router-dom";

interface StateProps {
  assignmentTestCategoriesReducer: any;
}

const initialState = Object.freeze({});

type Props = { dispatch: Dispatch<any> } & StateProps &
  RouteComponentProps<any>;
type State = typeof initialState;

class TestCategories extends Component<Props, State> {
  readonly state = initialState;
  componentDidMount() {
    this.getAssigntestCategories();
  }
  getAssigntestCategories = () => {
    this.props.dispatch(
      getAssignmentTestCategories(this.props["match"]["params"]["assignment"])
    );
  };

  render() {
    return (
      <Layout>
        <div>
          <BreadcrumbGroup
            items={Breadcrumb(this.props["match"]["params"]["assignment"])}
            onFollow={(e) => {
              e.preventDefault();
              this.props.history.push(e.detail.href);
            }}
          />
          <TestCategoriesTable
            data={this.props.assignmentTestCategoriesReducer.data}
            loadingStatus={
              this.props.assignmentTestCategoriesReducer.loadingStatus
            }
            getTestCategoriesData={this.getAssigntestCategories}
          ></TestCategoriesTable>
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    assignmentTestCategoriesReducer: state.assignmentTestCategoriesReducer,
  };
};

export default withRouter(connect<StateProps>(mapStateToProps)(TestCategories));
