import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  SpaceBetween,
  Grid,
  Container,
  Header,
  Link,
  Modal,
} from "@amzn/awsui-components-react-v3";
import SignIn from "../signin";
import constants from "../../config/constants";

const LandingPage = () => {
  const history = useHistory();
  const { authnReducer } = useSelector((state: any) => ({
    authnReducer: state.authnReducer,
  }));
  const { authnState } = authnReducer;
  const [modalVisible, setModalVisible] = React.useState(false);

  return (
    <div className="app-layout">
      <div className="app-title">
        <div className={"app-title-main-text"}>
          <div>WaaS</div>
        </div>
        <div className={"app-title-sub-text"}>
          <h3>
            Everything you need to create and manage your wireless testing, design and compliance —
            in one web interface
          </h3>
        </div>
        <br />
        <br />
        <div className={"app-login-btn-group"}>
          <SpaceBetween direction="horizontal" size="xs">
            {authnState === constants.UNAUTHENTICATED && (
              <Button
                variant="primary"
                className="app-button"
                onClick={() => setModalVisible(true)}
              >
                Sign In
              </Button>
            )}

            {authnState === constants.AUTHENTICATED && (
              <Button variant="primary" className="app-button" onClick={() => history.push("/")}>
                Go to Home Page
              </Button>
            )}
          </SpaceBetween>
        </div>
      </div>

      <div className="app-intro-area">
        <Grid gridDefinition={[{ colspan: 8 }, { colspan: 4 }]}>
          <div>
            <div className="app-intro-area-text">Start with Wireless as a Service today.</div>
            <div className="app-intro-area-image">
              <img
                src="//d1.awsstatic.com/management-console/aws-management-console.85b74a70bbb5749c7ce951bbfc62d2d8760a4c34.png"
                alt="placeholderimage"
              />
            </div>
          </div>
          <div>
            {/* feature overview  */}
            <Container
              header={
                <Header variant="h2" description="Container description">
                  Feature overview
                </Header>
              }
            >
              <ul>
                <li>Create test plan with your own configs & Assign Labs to test </li>
                <li>View & Upload test plan result (lab)</li>
                <li>Check test plan result & view visualizations</li>
              </ul>
            </Container>
            <br />
            <Container
              header={
                <Header variant="h2" description="Container description">
                  Additional Resources
                </Header>
              }
            >
              <ul>
                <li>
                  <Link
                    external
                    externalIconAriaLabel="Opens in a new tab"
                    href="https://w.amazon.com/bin/view/CSG/Wireless-Infrastructure-Software-Engineering/Team"
                  >
                    About Us
                  </Link>
                </li>
                <li>
                  <Link
                    external
                    externalIconAriaLabel="Opens in a new tab"
                    href="https://w.amazon.com/bin/view/CSG/Wireless-Infrastructure-Software-Engineering/ContactUs"
                  >
                    Contact Us
                  </Link>
                </li>
                <li>
                  <Link
                    external
                    externalIconAriaLabel="Opens in a new tab"
                    href="https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc"
                  >
                    Submit Questions
                  </Link>
                </li>
              </ul>

              {/* */}
            </Container>
          </div>
        </Grid>
      </div>

      <Modal
        onDismiss={() => setModalVisible(false)}
        visible={modalVisible}
        closeAriaLabel="Close modal"
        size="medium"
        header="Sign in"
      >
        <SignIn />
      </Modal>
    </div>
  );
};

export default LandingPage;
