import React, { Component } from "react";
import { Dispatch } from "redux";
import { connect } from "react-redux";
import {
  Button,
  Input,
  FormField,
  Select,
  TokenGroup,
} from "@amzn/awsui-components-react/polaris";
import constants, {
  DROPDOWN_NAMING_COLLECTION,
} from "../../../config/constants";
import {
  convertLabMap,
  getKeyByValue,
  mergeAssignArray,
  existedDSN,
} from "./assign-lab-helper-utils";
import { setUserRequestForm } from "../../../redux/actions/request-form-action";
import { capitalizeFirstLetter } from "../../../utils/general-utils";
import "./assign-lab-dsn-style.css";

interface StateProps {
  requestFormReducer: any;
}

// declare prop check
type Props = {
  dispatch: Dispatch<any>;
} & StateProps;

const defaultProps = Object.freeze({});
const initialState = Object.freeze({
  labOptions: [] as any[],
  labSelected: "",
  dsn: "",
  dsnError: "",
  firmware: "",
});

class AssignInput extends Component<Props> {
  static readonly defaultProps = defaultProps;
  readonly state = initialState;

  // constructor(props) {
  //   super(props);
  // }

  // add value to reducer form
  _assignToLab = () => {
    const { dsn, firmware, labSelected } = this.state;
    const { userSelectForm, labList } = this.props.requestFormReducer;
    const validInput: boolean = this._validate();

    if (validInput) {
      const updateSelected = { ...userSelectForm };
      const labLookup = convertLabMap(labList);
      const currentLabKey = getKeyByValue(labLookup, labSelected);

      // Create entry object with DSNs entered
      // Must declare addedObject to avoid TS error 'Object is possibly undefined'
      let addedObject: any;
      addedObject = {
        [constants.ASSIGN_LAB_KEY]: currentLabKey,
        [constants.ASSIGN_DSN_KEY]: [],
      };

      // Iterate through DSNs passed through to prepare for
      const dsnArray: string[] = this._splitDsnInput(dsn);
      dsnArray.forEach((dsnItem) => {
        addedObject[constants.ASSIGN_DSN_KEY].push({
          id: dsnItem,
          firmware: firmware,
        });
      });

      // Merge addedObject with DSNs into updateSelect, then clear DSN state since DSN are now assigned
      updateSelected[constants.REQUEST_FORM_ASSIGN_KEY] = mergeAssignArray(
        updateSelected[constants.REQUEST_FORM_ASSIGN_KEY],
        addedObject
      );

      this.setState({
        dsn: "",
      });

      this.props.dispatch(setUserRequestForm(updateSelected));
    }
  };

  _inputOnChange = (event: any) => {
    const currentValue: string = event.detail.value;
    const key = event.target.id;
    this._validate(key, currentValue);
    this.setState({
      [key]: currentValue,
    });
  };

  _splitDsnInput = (input: string): string[] => {
    // Controls DSN delimination regex, filter out empty strings
    return input.split(/(?:,| |\\n|<br \/>)+/).filter(Boolean);
  };

  _validate = (key: any = "", currentValue: string = ""): boolean => {
    const { dsn } = this.state;
    const currentDSN = key === constants.DSN_KEY ? currentValue : dsn;
    const { userSelectForm } = this.props.requestFormReducer;

    // Handle multiple DSN input possiblity
    const dsnArray: string[] = this._splitDsnInput(currentDSN);

    // 1st Validation Step - Get any duplicates within the new input
    const duplicateInput: Set<string> = new Set(
      dsnArray.filter((item, index) => dsnArray.indexOf(item) !== index)
    );

    // 2nd Validation Step - Get any input which already exists in submitted form
    const existedDSNArray: string[] = existedDSN(
      userSelectForm[constants.REQUEST_FORM_ASSIGN_KEY]
    );
    const existingInput: string[] = existedDSNArray.filter((value) =>
      dsnArray.includes(value)
    );

    // Default return values if validation is successful
    let validationErrMsg = "";
    let validInput: boolean = true;

    // Check for validation errors
    if (duplicateInput.size !== 0) {
      // Input has duplicates
      validationErrMsg = "Duplicate input:\n".concat(
        Array.from(duplicateInput).join("\n")
      );
      validInput = false;
    } else if (existingInput.length !== 0) {
      // Input already exists in submitted form
      validationErrMsg = "Following DSNs are already assigned:\n".concat(
        existingInput.join("\n")
      );
      validInput = false;
    }

    this.setState({
      dsnError: validationErrMsg,
    });
    return validInput;
  };

  _formatErrorText = (msg: string) => {
    if (msg !== "") {
      // Ensure \n in error message is displayed on new lines
      return msg.split(/\r\n|\n|\r/gm).map((line) => {
        return <div key={line}>{line}</div>;
      });
    } else {
      // No error message
      return "";
    }
  };

  _selectOnChange = ({
    detail: {
      selectedOption: { id },
    },
  }) => {
    this.setState({
      labSelected: id,
    });
  };

  _onAddressDismiss = (event: any) => {
    const { userSelectForm } = this.props.requestFormReducer;
    const currentSetting = { ...userSelectForm };
    if (currentSetting.assignments) {
      const targetID = parseInt(event.target.id);
      const currentList = currentSetting.assignments[targetID];
      if (currentList) {
        // Case 1: only one dsn exists in dsn_list -> delete the parent object
        // Case 2: multiple dsn exist in dsn_list -> remove target dsn from dsn_list
        if (currentList[constants.ASSIGN_DSN_KEY].length === 1) {
          // Case 1: delete parent object
          currentSetting.assignments = [
            ...currentSetting.assignments.slice(0, targetID),
            ...currentSetting.assignments.slice(targetID + 1),
          ];
        } else {
          // Case 2: remove target dsn from dsn_list
          currentList[constants.ASSIGN_DSN_KEY] = [
            ...currentList[constants.ASSIGN_DSN_KEY].slice(
              0,
              event.detail.itemIndex
            ),
            ...currentList[constants.ASSIGN_DSN_KEY].slice(
              event.detail.itemIndex + 1
            ),
          ];
        }
        this.props.dispatch(setUserRequestForm(currentSetting));
        // Re-validate input now that assigned DSN have changed
        this._validate();
      }
    }
  };

  render() {
    const { labList, labListLoadingStatus, userSelectForm } =
      this.props.requestFormReducer;
    const { dsn, firmware, dsnError, labSelected } = this.state;
    const labLookup = convertLabMap(labList);

    const assignCollectList = userSelectForm.assignments
      ? userSelectForm.assignments
      : [];

    const assignedList = assignCollectList.map((eachItem, index) => {
      return (
        <div key={index} className={"dsn-token-group"}>
          <div className="dsn-token-label">{labLookup[eachItem.lab_id]}:</div>
          <div className="dsn-token-values">
            <TokenGroup
              id={index}
              items={
                eachItem.dsn_list === undefined
                  ? []
                  : eachItem.dsn_list.map((object) => {
                      return {
                        id: `${object.id}-${object.firmware}`,
                        label: `${object.id}-${object.firmware}`,
                      };
                    })
              }
              onDismiss={this._onAddressDismiss}
            ></TokenGroup>
          </div>
        </div>
      );
    });

    return (
      <div>
        <div className={"dsn-to-lab"}>
          {/* dsn */}
          <FormField
            label={`${DROPDOWN_NAMING_COLLECTION.dsn}`}
            errorText={this._formatErrorText(dsnError)}
            stretch={true}
          >
            <div className={"dsn-to-lab-element"}>
              <Input
                id={"dsn"}
                placeholder={DROPDOWN_NAMING_COLLECTION.dsn}
                value={dsn}
                onChange={this._inputOnChange}
              ></Input>
            </div>
          </FormField>

          {/* lab select */}
          <FormField label={`${DROPDOWN_NAMING_COLLECTION.lab}`} stretch={true}>
            <div className={"dsn-to-lab-element"}>
              <Select
                id={"lab"}
                empty="No options"
                placeholder={"Lab List"}
                selectedLabel="Selected"
                options={
                  labList && Array.isArray(labList)
                    ? labList.map((eachLab) => {
                        return { id: eachLab.name, label: eachLab.name };
                      })
                    : []
                }
                statusType={
                  labListLoadingStatus === constants.LOADING_LOAD
                    ? constants.SELECT_STATUS_TYPE_LOADING
                    : null
                }
                selectedOption={
                  labSelected
                    ? {
                        id: labSelected,
                        label: capitalizeFirstLetter(labSelected),
                      }
                    : null
                }
                onChange={this._selectOnChange}
                filteringType="auto"
              ></Select>
            </div>
          </FormField>
          {/* firmware */}
          <FormField
            label={<span>{DROPDOWN_NAMING_COLLECTION.firmware}</span>}
            stretch={true}
          >
            <div className={"dsn-to-lab-element"}>
              <Input
                id={"firmware"}
                placeholder={DROPDOWN_NAMING_COLLECTION.firmware}
                value={firmware}
                onChange={this._inputOnChange}
              ></Input>
            </div>
          </FormField>
          {/* add to token */}
          <div className={"dsn-to-lab-add-btn"}>
            <Button
              disabled={!(dsn && firmware && labSelected)}
              onClick={this._assignToLab}
            >
              Add
            </Button>
          </div>
        </div>
        {assignedList}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    requestFormReducer: state.requestFormReducer,
  };
};

export default connect<StateProps>(mapStateToProps)(AssignInput);
