import React, { Component } from "react";
import {
  ExpandableSection,
  Alert,
  ColumnLayout,
  Tabs,
} from "@amzn/awsui-components-react/polaris";
// constants
import { FILE_TYPE, FILE_NAME_FORMAT } from "../../../config/upload-config";
import constants, { TEST_CATEGORY_NAME_MAP } from "../../../config/constants";

// Engineering file types
const SHOW_ENG_TYPES = [
  FILE_TYPE.ENG_POWER_TABLE,
  FILE_TYPE.ENG_ACTIVE_SCAN,
  FILE_TYPE.ENG_BE,
  FILE_TYPE.ENG_HAR,
];

const SHOW_DES_TYPES = [constants.TEST_CATEGORY_KEY_DESENSE];
const SHOW_RVR_TYPES = [constants.TEST_CATEGORY_KEY_RVR];
const SHOW_ANTENNA_TYPES = [constants.TEST_CATEGORY_KEY_ANTENNA];

class UploadFileSupport extends Component {
  render() {
    const complianceInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Engineering Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Compliance</h3>
            {/* column1 items */}
            {SHOW_ENG_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <a href={FILE_NAME_FORMAT[type].link}>
                    {FILE_NAME_FORMAT[type].example}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );

    const desenseInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Desense Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Desense</h3>
            {/* column1 items */}
            {SHOW_DES_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <a href={FILE_NAME_FORMAT[type].link}>
                    {FILE_NAME_FORMAT[type].example}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );
    const rvrInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: RvR Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering RvR</h3>
            {/* column1 items */}
            {SHOW_RVR_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <a href={FILE_NAME_FORMAT[type].link}>
                    {FILE_NAME_FORMAT[type].example}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );
    const antennaInfoFiles = (
      <ColumnLayout columns={1} variant="text-grid">
        <div data-awsui-column-layout-root="true">
          {/* Col_1: Antenna Files */}
          <div className="awsui-util-spacing-v-s">
            {/* column1 title */}
            <h3 className="awsui-util-p-n">From Engineering Antenna</h3>
            {/* column1 items */}
            {SHOW_ANTENNA_TYPES.map((type: string, index: number) => (
              <div key={index}>
                <div className="awsui-util-label">
                  {FILE_NAME_FORMAT[type].text}
                </div>
                <div>
                  <b>{"Name format: "}</b>
                  {FILE_NAME_FORMAT[type].format}
                </div>
                <div>
                  <b>{"Example: "}</b>
                  <a href={FILE_NAME_FORMAT[type].link}>
                    {FILE_NAME_FORMAT[type].example}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      </ColumnLayout>
    );

    const mappedTabInfo = [
      {
        label: TEST_CATEGORY_NAME_MAP.compliance,
        id: constants.TEST_CATEGORY_KEY_COMPLIANCE,
        content: <div>{complianceInfoFiles}</div>,
      },
      {
        label: TEST_CATEGORY_NAME_MAP.desense,
        id: constants.TEST_CATEGORY_KEY_DESENSE,
        content: <div>{desenseInfoFiles}</div>,
      },
      {
        label: TEST_CATEGORY_NAME_MAP.rvr,
        id: constants.TEST_CATEGORY_KEY_RVR,
        content: <div>{rvrInfoFiles}</div>,
      },
      {
        label: TEST_CATEGORY_NAME_MAP.antenna,
        id: constants.TEST_CATEGORY_KEY_ANTENNA,
        content: <div>{antennaInfoFiles}</div>,
      },
    ];

    return (
      <ExpandableSection header="Which files are supported">
        <Alert>
          The files you are going to upload should follow below file name
          formats.
        </Alert>
        <br />
        <Tabs
          tabs={mappedTabInfo}
          activeTabId={constants.TEST_CATEGORY_KEY_COMPLIANCE}
        />
      </ExpandableSection>
    );
  }
}

export default UploadFileSupport;
