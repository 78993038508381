const constants = {
  SIM_LAB_ONBOARD:
    "https://sim.amazon.com/issues/create?template=e9c29025-8c8d-43c9-b419-81d5c9d39675",
  SIM_PERMISSION_REQUEST:
    "https://sim.amazon.com/issues/create?template=930e61f4-eaf9-4afe-9357-d6036fe89c9d",
  SIM_BUG_REPORT:
    "https://sim.amazon.com/issues/create?template=c2d32687-eafb-4ffe-b3fc-dc7f0259ecbd",
  SIM_GENERAL_QUESTION:
    "https://sim.amazon.com/issues/create?template=3bfd1e37-f1c5-4537-b98e-52a84e32df44",
};

export default constants;
