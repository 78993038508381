import { designPaths } from "./../../../constants/route-paths";
export const designConfig = {
  headerText: "Design",
  headerUrl: "/design",
  sidebarItems: [
    {
      type: "link",
      text: "Design Tasks",
      href: designPaths.DESIGN_TASKS,
    },
    {
      type: "link",
      text: "Projects",
      href: designPaths.PROJECTS,
    },
    { type: "divider" },
    {
      type: "link",
      text: "Issue SIM",
      href: "https://sim.amazon.com/issues/create?template=94f5b1b7-59a8-4195-bdc9-15debfda57dc",
      external: true,
    },
  ],
};
