export const getLabInfo = (labId, labList) => {
  let labName = "";

  Array.isArray(labList) &&
    labList.forEach((eachLab) => {
      if (eachLab.id === labId) {
        labName = eachLab.name;
      }
    });

  return labName;
};

export const formatLabList = (labList) => {
  return labList
    ? labList.map((eachLab) => ({
        id: eachLab["id"],
        label: eachLab["name"],
        value: eachLab["name"],
      }))
    : [];
};

export const getRequestIdbyAssignId = (labAssignData, assignId) => {
  const resultObj = labAssignData.find(
    (eachObject) => assignId.toString() === eachObject.assignment_id.toString()
  );

  return resultObj ? resultObj.request_id : -1;
};

export const getAssignmentIdList = (labAssignData: Array<any>) => {
  const assignmentIdList: Set<number> = new Set(
    labAssignData.map((eachObj) => eachObj.assignment_id)
  );

  return assignmentIdList ? Array.from(assignmentIdList) : [];
};

export const updateQueryStringParameter = (url, key, value) => {
  var re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
  var separator = url.indexOf("?") !== -1 ? "&" : "?";
  if (url.match(re)) {
    //$n represents the nth capture group of the regular expression.
    return url.replace(re, "$1" + key + "=" + value + "$2");
  } else {
    return url + separator + key + "=" + value;
  }
};

export const queryStringIdExist = (
  labId,
  assignmentId,
  labList,
  AssignList
) => {
  if (labId && assignmentId && labList && AssignList) {
    if (
      labList.some((labObj) => labObj["id"] === labId) &&
      AssignList.some((labObj) => labObj["assignment_id"] === assignmentId)
    ) {
      return true;
    }
  }

  return false;
};
