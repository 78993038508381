import authnType from "../types/authn-type";
import constants from "../../config/constants";

const initialState = {
  authnState: constants.UNAUTHENTICATED,
  authIdp: undefined,
};

const authnReducer = (state = initialState, action) => {
  switch (action.type) {
    case authnType.SET_AUTHN_STATE: {
      return {
        ...state,
        authnState: action.authnState,
      };
    }
    case authnType.SET_AUTHN_IDP: {
      return {
        ...state,
        authIdp: action.authIdp,
      };
    }

    default:
      return state;
  }
};

export default authnReducer;
