/*
    wiki: https://wiki.labcollab.net/confluence/display/WDI/LensUpload

    File name format:

        Engineering:
            Power Table:  <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_power-table.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_power-table.csv
 
            Band Edge:    <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_band-edge.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_band-edge.csv
 
            Harmonics:    <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_harmonics.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_harmonics.csv
 
            Active Scan:  <:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_active-scan.csv
            e.g.: aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_active-scan.csv
*/

// key
export const FILE_TYPE = {
  ENG_POWER_TABLE: "engcom_power_table",
  ENG_BE: "engcom_band_edge",
  ENG_HAR: "engcom_harmonics",
  ENG_ACTIVE_SCAN: "engcom_active_scan",
};

export const FILE_NAME_FORMAT = {
  [FILE_TYPE.ENG_POWER_TABLE]: {
    text: "Engineering Power Table",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_power-table.csv",
    example:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_power-table.csv",
    link: "https://wise-internal-access-files.s3.us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_power-table.csv",
  },
  [FILE_TYPE.ENG_BE]: {
    text: "Engineering Band Edge",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_band-edge.csv",
    example: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_band-edge.csv",
    link: "https://wise-internal-access-files.s3.us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_band-edge.csv",
  },
  [FILE_TYPE.ENG_HAR]: {
    text: "Engineering Harmonics",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_harmonics.csv",
    example: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_harmonics.csv",
    link: "https://wise-internal-access-files.s3-us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_harmonics.csv",
  },
  [FILE_TYPE.ENG_ACTIVE_SCAN]: {
    text: "Engineering Active Scan",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_engcom_active-scan.csv",
    example:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_active-scan.csv",
    link: "https://wise-internal-access-files.s3.us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_engcom_active-scan.csv",
  },
  desense: {
    text: "Desense",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_<:firmware>_desense.csv",
    example:
      "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_firmware1_desense.csv",
    link: "https://wise-internal-access-files.s3-us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_firmware1_desense.csv",
  },
  rvr: {
    text: "RvR",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:dsn>_<:[testitem]>_<:channel>_<:link>_rvr.csv",
    example:
      "aucc_lidar_evt_1.0_P0B1QE0291220004_[WIFI_only]_Ch1_Downlink_rvr.csv",
    link: "https://wise-internal-access-files.s3-us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_P0B1QE0291220004_[WIFI_only]_Ch1_Downlink_rvr.csv",
  },
  antenna: {
    text: "Antenna",
    format:
      "<:category>_<:project>_<:build>_<:version>_<:config>_<:dsn>_antenna.xlsx",
    example: "aucc_lidar_evt_1.0_config1_P0B1QE0291220004_antenna.xlsx",
    link: "https://wise-internal-access-files.s3-us-west-2.amazonaws.com/engineering/upload-sample-files/aucc_lidar_evt_1.0_config1_P0B1QE0291220004_antenna.xlsx",
  },
};

export const UPLOAD_PAGE_SIZE = 1000;
