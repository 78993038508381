import React from "react";
import { Route, Redirect, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
// common components
import LandingPage from "./containers/landing/landing-page";
import SignOut from "./containers/signout";
import { AboutUs } from "@amzn/lens-react-ui";
import NotFoundPage from "./containers/not-found/not-found-page";
import Layout from "./containers/layout/layout";
import NotAuthz from "./containers/no-authz/no-authz";
// test components
import RequestForm from "./containers/request-form/request-form";
import ThirdPartyLab from "./containers/third-party-lab/third-party-lab";
import TestCategories from "./containers/third-party-lab/test-categories/test-categories";
import TestCases from "./containers/third-party-lab/testcases/testcases";
import RequestStatus from "./containers/request-status/request-status";
import RequestDetail from "./containers/request-detail/request-detail";
import PreUploadPage from "./containers/upload/pre-upload-page";
import UploadPage from "./containers/upload/upload-page";
// design components
import DesignProjects from "./containers-design/projects/index";
import DesignTasks from "./containers-design/design-tasks/index";
import DesignTask from "./containers-design/design-task/index";

// constants
import constants, { AUTH_USER_TYPE } from "./config/constants";
import { paths, testPaths, designPaths } from "./constants/route-paths";

const MainRouter = () => {
  const { authnReducer, authzReducer } = useSelector((state: any) => ({
    authnReducer: state.authnReducer,
    authzReducer: state.authzReducer,
  }));
  const { authnState } = authnReducer;
  const { authz } = authzReducer;
  const userType = authzReducer[constants.AUTH_PERMISSION_KEY]
    ? authzReducer[constants.AUTH_PERMISSION_KEY][constants.AUTH_USERTYPE_KEY]
    : null;

  if (authnState === constants.UNAUTHENTICATED) {
    return (
      <Switch>
        <Redirect from={paths.SIGN_IN} exact to={paths.LANDING} />
        <Route path={paths.LANDING} exact component={LandingPage} />
      </Switch>
    );
  }

  if (authz === -1) {
    return (
      <Switch>
        <Route path={paths.SIGN_OUT} exact component={SignOut} />
        <Route path={paths.NO_AUTHZ} exact component={NotAuthz} />
        <Redirect to={paths.NO_AUTHZ} />
      </Switch>
    );
  }

  return (
    <Switch>
      {/* common */}
      <Route path={paths.LANDING} exact component={LandingPage} />
      <Route path={paths.SIGN_OUT} exact component={SignOut} />
      <Route path={paths.NOT_FOUND} exact component={NotFoundPage} />
      <Route path={testPaths.NOT_FOUND} exact component={NotFoundPage} />
      <Route
        path={paths.ABOUT_US}
        exact
        component={() => (
          <Layout>
            <AboutUs />
          </Layout>
        )}
      />
      <Route path={designPaths.NOT_FOUND} exact component={NotFoundPage} />

      {/* test */}
      <Route path={testPaths.REQEST_FORM} exact component={RequestForm} />
      <Route path={testPaths.REQEST_STATUS} exact component={RequestStatus} />
      <Route path={testPaths.LAB} exact component={ThirdPartyLab} />
      <Route path={testPaths.LAB_ASSIGNMENT} exact component={TestCategories} />
      <Route path={testPaths.LAB_TEST_CATEGORY} exact component={TestCases} />
      <Route path={testPaths.REQUEST_DETAIL} exact component={RequestDetail} />
      <Route path={testPaths.PRE_UPLOAD} exact component={PreUploadPage} />
      <Route path={testPaths.UPLOAD} exact component={UploadPage} />

      {/* design */}
      <Route path={designPaths.PROJECTS} exact component={DesignProjects} />
      <Route path={designPaths.DESIGN_TASKS} exact component={DesignTasks} />
      <Route path={designPaths.DESIGN_TASK} exact component={DesignTask} />

      {/* redirect */}
      <Redirect from="/branding" exact to={paths.LANDING} />
      <Redirect from="/3rd-lab" exact to={testPaths.LAB} />

      <Redirect from="/" exact to="/test" />
      <Redirect
        from="/test"
        exact
        to={userType === AUTH_USER_TYPE.vendor ? testPaths.LAB : testPaths.REQEST_STATUS}
      />

      <Redirect from="/design" exact to={designPaths.DESIGN_TASKS} />

      <Redirect from="/test" to={testPaths.NOT_FOUND} />
      <Redirect from="/design" to={designPaths.NOT_FOUND} />
      <Redirect to={paths.NOT_FOUND} />
    </Switch>
  );
};

export default MainRouter;
